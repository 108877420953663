import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";

function About() {
    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>About agreements</title>
                <meta id="meta-description" name="description" content="Information as to what a Separation Agreement will allow you and your partner to do. " />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, DIY Separation Agreements, Quick Separation Agreement, About Agreement, Separation Process, Agreement Online, Solicitor Advice, " />
                <meta id="og-title" property="og:title" content="About agreements" />
            </MetaTags>
            <div className="main_left col-lg-9 col-md-8 col-sm-12">
                <h1>About Separation Agreements</h1>

                <p>Demand for Separation Agreements in Britain today is rising and for good reason. Separation
                    Agreements mean <b>no court interference, no legal</b> jargon and <b>no fuss</b>, which means you
                    have more time to reflect on decisions that are important to you.</p>

                <p>In choosing to form a Separation Agreement, a married couple has no obligation to inform any legal
                    professionals of their decision. They can avoid the formalities of a court room and welcome the
                    privacy of discretion. The agreements are an effective way of regulating your split and making all
                    the important decisions yourself without your judgement being clouded by legal jargon.</p>

                <p>Separation is a confusing time and we aim to make the process as simple as possible.</p>

                <p>Our Separation Agreement covers the division of debt, division of property, possessions and policies,
                    provision of childcare and maintenance, child visitation, who will live in the marital home, who is
                    responsible for payment of bills, mortgage upkeep, plus much more...</p>

                <p>Our <b><u>Personalised Package</u></b> offers you an easy-to-understand online questionnaire that
                    enables us to complete your legal contract plus give you access to all the essential documentation,
                    advice and examples. </p>

                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif" imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html" />
                </div>
                <a href="services.html">
                    <div className="index_btn2"></div>
                </a> */}
                <div className="bottom d-flex justify-content-between mb-4 mt-4 pt-3">
                    <div className="l_btn">
                        <a href="order_now.html" className="btn_1" title="Order now">
                            <span className="b_inner">Order Now</span>
                        </a>
                        
                    </div>
                    <div className="r_btn">
                        <a href="services.html" className="btn_1" title="Read More">
                            <span className="b_inner">Read More</span>
                        </a>
                    </div>
                </div>
            </div>
            <ContentRight />
        </div>
    );
}

export default About;