import React from "react";
import {Link} from "react-router-dom";

function Footer() {
    return (
        <footer className="footer text-center" id="footer">
            <div className="row container" id="footer_wrapper">
                <div className="col-md-3 col-lg-3 col-6 pb-2 pt-lg-2 pt-md-2">
                    <Link to="wills.html" title="special offer" className="">
                        <img src="images/special-offer.gif" alt="special offer" title="Special Offer" border="0" />
                    </Link>
                </div>
                <div className="col-md-3 col-lg-3 col-6 pb-2 pt-lg-2 pt-md-2">
                    <Link to="free_ebook.html" title="Free Ebook" className="">
                        <img src="images/free-ebook.gif" title="Free book" alt="free ebook" border="0" />
                    </Link>
                </div>
                <div className="col-md-3 col-lg-3 col-6 pb-2 pt-lg-2 pt-md-2">
                    <Link to="testimonials.html" title="Feed Back" className="">
                        <img src="images/feedback.gif" title="Feedback" alt="feedback" border="0"/>
                    </Link>
                </div>
                <div className="col-md-3 col-lg-3 col-6 pb-2 pt-lg-2 pt-md-2">
                    <Link to="guarantee.html" title="Risk Free" className="">
                        <img src="images/risk-free.gif" alt="risk free" title="risk free" border="0" />
                    </Link>
                </div>
            </div>

            <div class="container-fluid d-lg-block d-md-block d-none">
                <div class="row p_bg d-flex justify-content-between align-items-center">
                    <div class="col-12 col-lg-9 col-md-9 text-left">
                        <p class="h3 contact__">Confused About Divorce? Call<span class="ph_no">0800 440 2512</span>
                        or drop us a message.
                        </p>
                    </div>
                    <div class="col-12 col-lg-3 col-md-3 text-right">
                        <a class="pull-right btn-warning-3d fs-18 btn_1" href="/support.html" title="Support">
                            <span class="b_inner">Contact Us</span>
                        </a>
                    </div>
                </div>
            </div>

            <div class="container-fluid d-lg-none d-md-none d-block">
                <div class="row d-flex justify-content-between align-items-center res_contact">
                    <div class="col text-center pb-5 pt-4">
                        <h1 class="h3 contact__ text-center pb-1 p_col">Confused About Divorce? </h1>
                        <p class="h1 text-center pb-4 text-white">Free Help & Advice</p>
                        <a class="pull-right btn-warning-3d fs-18 btn_1" href="/support.html" title="Contact">
                            <span class="b_inner">Contact 0800 440 2512</span>
                        </a>                      
                    </div>
                </div>
            </div>

            <div class="container py-3 mt-2 last_footer">
                <div className="row py-4 d-lg-block d-md-block d-none">
                    <div className="col">
                        <h5 class="text-white text-lg-center">Brand logo</h5>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-sm-3 col-lg-4 col-sm-4 col-sm-offset-1 text-lg-left text-md-left text-center d-flex align-items-center flex-column pb-4">                       
                        <ul class="list-unstyled">
                        <h2 class="h5 first text-lg-left text-md-left text-center">GETTING STARTED</h2>
                            <li>
                                <a href="/services.html" title="Service">Services</a>
                            </li>
                            <li>
                                <a href="/" title="Special Offer">Special Offer</a>
                            </li>
                            <li>
                                <a href="/" title="Free eBook">Free eBook</a>
                            </li>
                            <li>
                                <a href="/" title="24 Hour Tracking">24 Hour Tracking</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-3 col-lg-4 col-sm-4 col-sm-offset-1 text-lg-left text-md-left text-center d-flex align-items-center flex-column pb-4">                        
                        <ul class="list-unstyled">
                        <h2 class="h5 text-lg-left text-md-left text-center">HELP &amp; ADVICE</h2>
                            <li>
                                <a href="/" title="How To Diverce">How To Divorce</a>
                            </li>
                            <li>
                                <a href="/" title="Divorce Resources">Divorce Resources</a>
                            </li>
                            <li>
                                <a href="/faq.html" title="FAQ">FAQ</a>
                            </li>
                            <li>
                                <a href="/contact.html" title="Contact Us">Contact Us</a>
                            </li>
                            <li>
                                <a href="/guarantee.html" title="Guarantee">Guarantee</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col-sm-3 col-lg-4 col-sm-4 col-sm-offset-1 text-lg-left text-md-left text-center d-flex align-items-center flex-column pb-4">                       
                        <ul class="list-unstyled">
                        <h2 class="h5 text-lg-left text-md-left text-center">ABOUT US</h2>
                            <li>
                                <a href="/why-choose-us.html" title="Why Choose Us">Why Choose Us</a>
                            </li>
                            <li>
                                <a href="/testimonials.html" title="Testimonials">Testimonials</a>
                            </li>
                            <li>
                                <Link to="/privacy_policy.html" title="Privacy policy">Privacy policy</Link>
                            </li>
                            <li>
                                <Link to="/disclaimer.html" title="disclaimer">Disclaimer</Link>
                            </li>
                            <li>
                                <Link to="/terms.html" title="terms and conditions">Terms and conditions</Link>
                            </li>
                            <li>
                                <Link to="/sitemap.html" title="sitemap">Sitemap</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="footer_links text-center d-flex justify-content-center py-2">
                <span className="copyright">&nbsp;© Separation Agreement UK 1999-2021<Link to="sitemap.html" title="sitemap"><img src="images/xml.gif" alt="sitemap" title="Site Map" /></Link></span>
            </div>
        </footer>
    );
}

export default Footer;