import React from "react";
import ContentRight from "../../components/ContentRight";
import MetaTags from "react-meta-tags";

function Disclaimer() {
        return (
            <div className="content_wrapper terms">
                <MetaTags>
                    <title>Disclaimer</title>
                    <meta id="meta-description" name="description" content="Use of our Separation Agreement service is subject to the conditions detailed in our Disclaimer" />
                    <meta id="meta-keywords" name="keywords" content="Separation Agreement, DIY Agreement Quick Agreement, Disclaimer, Online Agreement, Data Accountability, Trust Act, Separation Agreement Advice, Cheap Separation Agreement" />
                    <meta id="og-title" property="og:title" content="Disclaimer" />
                </MetaTags>
                <div className="main_left col-md-9 col-sm-12">
                <h2>Disclaimer</h2>

                <p>Use of the Separation Agreement UK online service is subject to the following Terms and
                    Conditions: </p>

                <h3>1) Jurisdiction</h3>

                <p>All documentation and advice contained on the separation-agreement.co.uk website relates to
                    proceedings lodged in Courts in England and Wales and not in any other region of the United Kingdom
                    or outside the United Kingdom.</p>

                <p>Separation Agreement UK documentation has no jurisdiction or legality in countries such as members of
                    the European Union or the United States or Canada.</p>

                <p>This Agreement is covered by the Laws of England and Wales. Any rights not expressly granted herein
                    are reserved. These Terms and Conditions shall not affect your statutory rights as a consumer.</p>

                <h3>2) Limitations</h3>

                <p>Separation Agreement UK provides clients with documents and advice on the completion of documents
                    required to initiate a Separation Agreement in England and Wales.</p>

                <p>Separation Agreement UK does not supply actual or general legal advice in such proceedings and no
                    such legal advice should be implied in the use of this service.</p>

                <p>Separation Agreement UK does not accept liability or responsibility for any additional advice that
                    clients seek in conjunction with their documentation and/or advice.</p>

                <p>Separation Agreement UK does not accept any liability in relation to any actual proceedings issued by
                    clients.</p>

                <p>Separation Agreement UK provides documentation on the understanding that clients are acting in person
                    in relation to proceedings.</p>

                <p>Documents and advice contained on the separation-agreement.co.uk website are not made available for
                    any other purpose.</p>

                <h3>3) General</h3>

                <p>Whilst every care has been taken in the preparation of documents and advice, clients must note that
                    in the majority of cases these need to be tailored to suit their particular circumstances. It is a
                    condition of using the separation-agreement.co.uk website that clients accept that the
                    documentation, software purchased, or the facilities used, are suitable to be used by them in
                    conjunction with proper advice and adaptation for their particular requirements.</p>

                <p>Separation Agreement UK does not accept responsibility or liability for any losses incurred by its
                    users.</p>

                <p>If a Court of Law later decides that any part of this disclaimer is not reasonable or cannot be
                    enforced for any reason, that decision will not affect or relate to any other parts of the
                    disclaimer which will be enforced or continue to be enforceable in any event.</p>

                <h3>Privacy Policy</h3>

                <p>Separation Agreement UK is committed to protecting the identity and privacy of our users. We will
                    only use information collected in the process of transactions in a strictly lawful manner in
                    accordance with the Data Protection Act 1998.</p>

                <p>By purchasing from us, your details will be entered into our customer database. We will never sell or
                    otherwise share your name, email address, or other personal information with any other business or
                    marketing campaign without your permission. We will however, send you information from time to time
                    detailing our product updates and special offers. You may opt-out of receiving these notifications
                    at any time after purchasing by using the unsubscribe link provided. All members' personal data will
                    be saved in accordance with UK and EU privacy laws and the Data Protection Act 1998.</p>

                <p>Users should be aware that Separation Agreement UK retains no information relating to client’s credit
                    card details; this information is only retained by the Merchant Service Provider.</p>

                <h3>Modifications</h3>

                <p>Separation Agreement UK reserves the right to change the Terms and Conditions of business and
                    disclaimers relating to our website service.</p>

                {/* <a href="services.html">
                    <div className="index_btn2"></div>
                </a> */}
                <div className="bottom d-flex justify-content-between mb-4 mt-4 pt-3">
                    <div className="l_btn">
                        {/* <a href="order_now.html" className="btn_1">
                            <span className="b_inner">Order Now</span>
                        </a> */}
                        
                    </div>
                    <div className="r_btn">
                        <a href="services.html" className="btn_1" title="Read More">
                            <span className="b_inner">Read More</span>
                        </a>
                    </div>
                </div>
            </div>
            <ContentRight />
        </div>
        );
}

export default Disclaimer;