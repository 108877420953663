import React, { useState } from "react";
import {Link} from "react-router-dom";
import $ from 'jquery';

$(document).ready(function () {
    console.log("hello");

    $('.nav-link').click(function() {
        $('.menu-btn').click();
    });
    $('ul li').click(function(){
        $('ul li').removeClass("active");
        $(this).addClass("active");
    });  
});

function Header(props) {

    // const [style, setStyle] = useState("show");
  
    // const changeStyle = () => {
    //     console.log("you just clicked");
    
    //     setStyle("hide");
    // };
    
    

    // const navRef = React.useRef(null);

    // const onToggleClick = (e) => {
    //     navRef.current.classList.toggle("show");
    //   };

    return (

    <div class="menu-wrapper">
        <header class="fixed-top header d-flex flex-colum align-items-center row m-0 py-2 container">
            <label class="menu-icon order-1 col-lg-2 col-2 mb-0" for="menu-btn">
                <span class="navicon mt-2">
                    {/* <span class="inner">Menu</span> */}
                </span>
            </label>

            <a href="/" title="Brand" class="logo order-1 col-8 col-md-6 col-lg-2 d-flex justify-content-center justify-content-md-start">Brand Logo
                {/* <img src="images/logo.gif" alt="separation agreement" border="0"/> */}
            </a>

            
                <div class="navbar_contact hidden-xs call order-3 col-2 col-md-6 col-lg-3 d-flex justify-content-end text-light">
                    <div class="d-block d-lg-none d-md-none">
                        <img class="" data-src="images/call-us-phone.png" alt="Call Us" title="Call Us" src="images/call-us-phone.png"/>
                    </div>

                    <div class="contact-num hidden-xs text-right d-none d-lg-block d-md-block">
                        
                        <div class="call-us">call us on</div>
                        <div class="figure">0800 440 2512</div>
                        <div class="navbar_link">
                            <a href="https://tracker.quickie-divorce.com" title="Login">Login</a>
                                |
                            <a href="/support.html" title="Support">Support</a>
                                |
                            <a href="/blog" title="Blog">Blog</a>
                        </div>
                            
                    </div>
                </div>
                {/* <img src="images/phone-number.gif" alt="phone number 0800 440 2512" border="0"/> */}
            
            <input class="menu-btn" type="checkbox" id="menu-btn" />
            
            <ul className="menu order-3 order-lg-2 col-lg-7 col-12 d-flex justify-content-center justify-content-lg-start flex-column flex-md-row ">
                <li className="nav-item active">
                        <Link className="nav-link"  to="/">Home</Link>
                    </li>
                    {/* <li className="menu-lim">|</li> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="about.html" title="About agreement">About agreement</Link>
                    </li>
                    {/* <li className="menu-lim">|</li> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="services.html" title="Services">Services</Link>
                    </li>
                    {/* <li className="menu-lim">|</li> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="order_now.html" title="Order now">Order now</Link>
                    </li>
                    {/* <li className="menu-lim">|</li> 
                    <li className="nav-item">
                        <Link className="nav-link" to="testimonials.html title="testimonial">Testimonials</Link>
                    </li>*/}
                    {/* <li className="menu-lim">|</li> 
                    <li className="nav-item">
                        <Link className="nav-link" to="guarantee.html">Guarantee</Link>
                    </li>*/}

                    {/* <li className="menu-lim">|</li> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="contact.html" title="Contact us">Contact us</Link>
                    </li>
                    {/* <li className="menu-lim">|</li> 
                    <li className="nav-item">
                        <Link className="nav-link" to="why-choose-us.html">Why choose us</Link>
                    </li>*/}
                    {/* <li className="menu-lim">|</li> 
                    <li className="nav-item">
                        <Link className="nav-link" to="faq.html">FAQ</Link>
                    </li>*/}
            </ul>
        </header>
    </div>

    )
        ;
}

export default Header;