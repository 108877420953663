import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Footer, Header} from "./components";
import $ from "jquery";
import About from "./containers/About/About";
import Home from "./containers/Home/Home";
import Services from "./containers/Services/Services";
import Testimonials from "./containers/Testimonials/Testimonials";
import Guarantee from "./containers/Guarantee/Guarantee";
import WhyChooseUs from "./containers/WhyChooseUs/why-choose-us";
import Faq from "./containers/Faq/Faq";
import FreeEBook from "./containers/FreeEBook/FreeEBook";
import Wills from "./containers/Wills/Wills";
import PersonalService from "./containers/PersonalService/PersonalService";
import Support from "./containers/Support/Support";
import Contact from "./containers/Contact/Contact";
import OrderNow from "./containers/OrderNow/OrderNow";
import Terms from "./containers/Terms/Terms";
import Disclaimer from "./containers/Disclaimer/Disclaimer";
import PrivacyPolicy from "./containers/PrivacyPolicy/PrivacyPolicy";
import Sitemap from "./containers/Sitemap/Sitemap";
import Archive from "./containers/Archive/Archive";
//import SolicitorService from "./containers/SolicitorService/SolicitorService";
import Article from "./containers/Articles/Article";
import Article2 from "./containers/Articles/Article2";
import Article3 from "./containers/Articles/Article3";
import Article4 from "./containers/Articles/Article4";
import Article5 from "./containers/Articles/Article5";
import Article6 from "./containers/Articles/Article6";
import Article7 from "./containers/Articles/Article7";
import Article8 from "./containers/Articles/Article8";
import Article9 from "./containers/Articles/Article9";
import Article10 from "./containers/Articles/Article10";



$(document).ready(function () {

    // It works when menu for mobile devices is showing. When clicking to any link on the menu the menu will hide afterward
    $(".nav-link").on("click", function () {
        $("ul.menu").hide();
    });
});

function App() {
    return (
        <div className="App">
            <Router>
                <div className="container p-0 px-lg-2">
                    <Header />
                    <div id="banner" class="row">
                        <div className="banner_image d-none d-lg-block d-md-block col-6"></div>
                        <div className="banner_text d-block col-6">
                            <h1 className="title  text-sm-center text-lg-end text-lg-right text-md-left mr-md-5 mr-lg-5 mb-3 text-center">Need a Separation?</h1>
                            <p className="tagline text-sm-center text-lg-end text-lg-right mr-lg-5 mr-md-5 text-md-left text-center">We believe we've created 
                                <div>the quickest, simplest &amp; best quality solution</div>
                                available in the UK today
                            </p>
                            {/* <a href="order_now.html">
                                <div className="banner_btn"></div>
                            </a> */}
                        </div>
                    </div>
                    <div id="xsnazzy">
                        <div className="xboxcontent">
                            <Switch>
                                <Route path="/" exact component={() => <Home/>}/>
                                <Route path="/about.html" exact component={() => <About/>}/>
                                <Route path="/services.html" exact component={() => <Services/>}/>
                                <Route path="/testimonials.html" exact component={() => <Testimonials/>}/>
                                <Route path="/guarantee.html" exact component={() => <Guarantee/>}/>
                                <Route path="/why-choose-us.html" exact component={() => <WhyChooseUs/>}/>
                                <Route path="/faq.html" exact component={() => <Faq/>}/>
                                <Route path="/free_ebook.html" exact component={() => <FreeEBook/>}/>
                                <Route path="/wills.html" exact component={() => <Wills/>}/>
                                <Route path="/personal-service.html" exact component={() => <PersonalService/>}/>
                                <Route path="/support.html" exact component={() => <Support/>}/>
                                <Route path="/contact.html" exact component={() => <Contact/>}/>
                                <Route path="/order_now.html" exact component={() => <OrderNow/>}/>
                                <Route path="/terms.html" exact component={() => <Terms/>}/>
                                <Route path="/disclaimer.html" exact component={() => <Disclaimer/>}/>
                                <Route path="/privacy_policy.html" exact component={() => <PrivacyPolicy/>}/>
                                <Route path="/sitemap.html" exact component={() => <Sitemap/>}/>
                                <Route path="/archive.html" exact component={() => <Archive/>}/>
                                <Route path="/article.html" exact component={() => <Article/>}/>
                                <Route path="/article2.html" exact component={() => <Article2/>}/>
                                <Route path="/article3.html" exact component={() => <Article3/>}/>
                                <Route path="/article4.html" exact component={() => <Article4/>}/>
                                <Route path="/article5.html" exact component={() => <Article5/>}/>
                                <Route path="/article6.html" exact component={() => <Article6/>}/>
                                <Route path="/article7.html" exact component={() => <Article7/>}/>
                                <Route path="/article8.html" exact component={() => <Article8/>}/>
                                <Route path="/article9.html" exact component={() => <Article9/>}/>
                                <Route path="/article10.html" exact component={() => <Article10/>}/>
                            </Switch>
                        </div>
                    </div>
                    <Footer />
                </div>
            </Router>
        </div>
    );
}

export default App;