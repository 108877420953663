import React from "react";
import Button from "../../components/Button";
import ContentRight from "../../components/ContentRight";
import {Link} from "react-router-dom";
import MetaTags from "react-meta-tags";


function Sitemap() {

    return (
        <div className="content_wrapper">
            <MetaTags>
                <title>Sitemap</title>
                <meta id="meta-description" name="description" content="Sitemap contains a breakdown of the content in all main pages of the Separation Agreement UK website." />
                <meta id="meta-keywords" name="keywords" content="Separation Agreement, DIY Separation, Quick Separation, Terms and Conditions, Agreement Terms, Agreement Conditions, Online Separation Agreement, Separation Advice, Agreement Information, Cheap Agreement " />
                <meta id="og-title" property="og:title" content="Sitemap" />
            </MetaTags>
            <div className="main_left col-md-9 col-sm-12">
                    <h1>Sitemap</h1>

                        <h3><Link to="about.html">About Agreements</Link></h3>
                        <p>Information as to what a Separation Agreement will allow you and your partner to do</p>

                        <h3><Link to="archive.html">Articles Archive</Link></h3>
                        <p>Archive contains a list of articles about separation agreements</p>

                        <h3><a href="https://tracker.separation-agreement.co.uk"  rel="noreferrer"  target="_blank">Client Login</a></h3>
                        <p>Login to your account</p>

                        <h3><Link to="contact.html">Contact us </Link></h3>
                        <p>How to contact Separation Agreement UK and our various contact details</p>

                        <h3><Link to="disclaimer.html">Disclaimer</Link></h3>
                        <p>Use of our Separation Agreement service is subject to the conditions detailed in our
                            Disclaimer</p>

                        <h3><Link to="faq.html">FAQ</Link></h3>
                        <p>Frequently asked questions about Separation Agreements, marital separation, the support we
                            offer and other aspects of our service</p>

                        <h3><Link to="free_ebook.html">FREE eBook</Link></h3>
                        <p>The Essential Guide to Separation Agreements</p>

                        <h3><Link to="guarantee.html">Guarantee</Link></h3>
                        <p>We believe we have developed the quickest, simplest, best quality Separation solution
                            available in the UK</p>

                        <h3><Link to="/">Home</Link></h3>
                        <p>Separation Agreement UK - We provide support and documentation to help those going through
                            marital separation </p>

                        <h3><Link to="order_now.html">Order Now</Link></h3>
                        <p>Separation Agreements allow couples to formalise their separation without the hassle of a
                            divorce</p>

                        <h3><Link to="personal-service.html">Personalised service</Link></h3>
                        <p>Our personalised service provides customers with a unique, tailored Separation Agreement</p>

                        <h3><Link to="privacy_policy.html">Privacy Policy</Link></h3>
                        <p>At Separation Agreement UK we take every step to ensure our customer’s privacy is
                            protected</p>

                        <h3><Link to="services.html">Services</Link></h3>
                        <p>No other online Separation Agreement service provides you with a comparable quality or
                            value-for-money service</p>

                        <h3><Link to="support.html">Support</Link></h3>
                        <p>Our team of experts can give guidance on all matters relating to our Separation Agreement
                            process, and can also help you choose which service is right for you</p>

                        <h3><Link to="terms.html">Terms and Conditions</Link></h3>
                        <p>Customers must agree to our terms and conditions before using the service </p>

                        <h3><Link to="testimonials.html">Testimonials</Link></h3>
                        <p>What our customers had to say about our Separation Agreements and our marital separation
                            service </p>

                        <h3><Link to="why-choose-us.html">Why a Separation Agreement?</Link></h3>
                        <p>Separation-Agreement.co.uk was set up in 2005 by a group of Legal Experts committed to making
                            the processing of downloadable Separation Agreements as quick, simple and affordable as
                            possible</p>

                        <h3><Link to="wills.html">Discounted Will</Link></h3>
                        <p>Purchase a Separation Agreement NOW and you'll receive your completed Last Will and Testament
                            at an EXCLUSIVE Special Offer price of £10 (usually £39)</p>

                            <div className="bottom d-flex justify-content-between mb-4 mt-4 pt-3">
                                <div className="l_btn">
                                    <a href="order_now.html" className="btn_1">
                                        <span className="b_inner">Order Now</span>
                                    </a>
                                    
                                </div>
                                <div className="r_btn">
                                    <a href="services.html" className="btn_1" >
                                        <span className="b_inner">Read More</span>
                                    </a>
                                </div>
                            </div>
                {/* <div className="index_btn1">
                    <Button imageName="order_now3a" imagePath="images/BTN-order-now.gif"
                            imagePathRestore="images/BTN-order-now-over.gif"
                            alt="Order Now" to="order_now.html"/>
                </div>
                <Link to="services.html">
                    <div className="index_btn2"></div>
                </Link> */}
            </div>
            <ContentRight/>
        </div>
    );
}

export default Sitemap;